import React from 'react'
import MainView from './components/main-view/main-view';
import './App.css';

function App() { 

	return (
		<div className="App"  >

		<MainView />

		</div>
		
	)
}

export default App;